import { graphql, useStaticQuery } from 'gatsby'
import { getFluidImage } from 'helpers/getFluidImage'

export const fetchPortfolioImg = () => {
  const { allFile } = useStaticQuery(graphql`
    query {
      allFile(
        filter: {
          extension: { regex: "/(jpg)|(png)|(jpeg)/" }
          relativeDirectory: { eq: "portfolio" }
        }
      ) {
        edges {
          node {
            base
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  `)

  return getFluidImage(allFile)
}

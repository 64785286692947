import styled from 'styled-components'
import { Col2, Container, PrimaryTitle, Row } from 'styles/Global'

export const PortfolioHero = styled(Row)`
  position: relative;
  padding: 0 0 95px;
  min-height: calc(100vh - 95px);
  flex-direction: row;
  ${PrimaryTitle} {
    margin-bottom: 1rem;
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.xs}px) {
    margin-top: 95px;
    padding: 6rem 0 0;
    flex-direction: column;
    justify-content: flex-start;
    min-height: calc(100vh - (95px + 6rem));
    ${Col2}:first-child {
      margin-bottom: 1rem;
    }
  }
`

export const PortfolioHeroImg = styled.div`
  width: 50%;
  margin: 95px auto 0;
  @media (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    width: 70%;
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.xs}px) {
    margin-top: 0;
  }
`

export const ContainerBlocContact = styled(Container)`
  overflow: hidden;
`

export const homeLogo = [
  {
    source: '/svg/portfolio/qomben.svg',
    alt: 'quomben logo',
  },
  {
    source: '/svg/portfolio/lecatwalk.svg',
    alt: 'catwalk logo',
  },
  {
    source: '/svg/portfolio/intental.svg',
    alt: 'intental logo',
  },
  {
    source: '/svg/portfolio/mobioos.svg',
    alt: 'mobioos logo',
  },
]

export const portfolioLogo = [
  {
    source: '/svg/portfolio/lip6.svg',
    alt: 'lip6 logo',
  },
  {
    source: '/svg/portfolio/inria.svg',
    alt: 'inria logo',
  },
  {
    source: '/svg/portfolio/cnrs.svg',
    alt: 'cnrs logo',
  },
  {
    source: '/svg/portfolio/lirmm.svg',
    alt: 'lirmm logo',
  },
]

import React, { FunctionComponent } from 'react'
import { Trans } from '@lingui/macro'
import { Col2, Container, MainText, PrimaryTitle, TextRed } from 'styles/Global'
import {
  ContainerBlocContact,
  PortfolioHero,
  PortfolioHeroImg,
} from 'styles/pages/portfolio.style'
import { fetchPortfolioImg } from 'hooks/fetch-portfolio-img'
import { ImageQueryModel } from 'models/imageQuery.model'

import Layout from 'components/Layout'
import Image from 'components/Image'
import SEO from 'components/Seo'
import IndicatorScroll from 'components/atoms/IndicatorScroll/IndicatorScroll'
import BlocContact from 'components/molecules/BlocContact/BlocContact'
import Footer from 'components/molecules/Footer/Footer'
import Header from 'components/organisms/Header/Header'
import PortfolioBlocs from 'components/organisms/PortfolioBlocs/PortfolioBlocs'
import { I18n } from "@lingui/react"

interface Props {}

const PortfolioPage: FunctionComponent<Props> = () => {
  const images: ImageQueryModel = fetchPortfolioImg()

  return (
    <Layout>
      <I18n>
        {({ i18n }) => (
          <SEO
            title="Portfolio"
            description={i18n._("portfolio.metaDescription")}
          />
        )}
      </I18n>
      <Header />
      <Container>
        <PortfolioHero>
          <Col2>
            <PrimaryTitle>
              <Trans id="portfolio.main.title">
                D'une <TextRed>idée</TextRed> à une <TextRed>startup</TextRed>
                prête à faire des affaires.
              </Trans>
            </PrimaryTitle>
            <MainText>
              <Trans id="portfolio.subtitle">
                Découvrez comment nos startups révolutionnent leur industrie.
              </Trans>
            </MainText>
          </Col2>
          <Col2>
            <PortfolioHeroImg className="rellax">
              <Image
                fluid={images.heroAvatar.fluid}
                alt={images.heroAvatar.alt}
              />
            </PortfolioHeroImg>
          </Col2>
          <IndicatorScroll text="Scroll" />
        </PortfolioHero>
      </Container>
      <PortfolioBlocs images={images} />
      <ContainerBlocContact>
        <BlocContact />
      </ContainerBlocContact>
      <Footer />
    </Layout>
  )
}

export default PortfolioPage

import styled from 'styled-components'
import { TextRed } from 'styles/Global'

export const ListTagsContainer = styled.div<{ alignRight?: boolean }>`
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: ${({ alignRight }) =>
    alignRight ? 'flex-end' : 'flex-start'};
  font-family: ${({ theme }) => theme.Fonts.redHat.black};
  margin-bottom: 0.5rem;
  font-size: 14px;
  flex-wrap: wrap;
  ${TextRed}:not(:last-child) {
    margin-right: 1rem;
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.xs}px) {
    font-size: 12px;
  }
`

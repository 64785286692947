import React, { FunctionComponent } from 'react'
import { TextRed } from 'styles/Global'
import { ListTagsContainer } from './ListTags.style'

interface Props {
  data: React.ReactElement[]
  alignRight?: boolean
}

const ListTags: FunctionComponent<Props> = ({ data, alignRight }) => {
  return (
    <ListTagsContainer alignRight={alignRight}>
      {data.map((item, i) => (
        <TextRed as="p" key={i}>
          {item}
        </TextRed>
      ))}
    </ListTagsContainer>
  )
}

export default ListTags

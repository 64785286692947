import React, { FunctionComponent } from 'react'
import { PortfolioLogosItem, PortfolioLogosList } from './PortfolioLogos.style'
import { homeLogo, portfolioLogo } from './PortfolioLogos.data'

interface Props {
  isHome?: boolean
}

const PortfolioLogos: FunctionComponent<Props> = ({ isHome }) => {
  const dataLogos = isHome ? homeLogo : portfolioLogo

  return (
    <PortfolioLogosList isHome={isHome}>
      {dataLogos.map((item, i) => (
        <PortfolioLogosItem key={i}>
          <img src={item.source} alt={item.alt} />
        </PortfolioLogosItem>
      ))}
    </PortfolioLogosList>
  )
}

export default PortfolioLogos

import styled from 'styled-components'
import { ButtonBlack, Col2, Container, PrimaryTitle, Row } from 'styles/Global'
import Image from 'components/Image'

export const PortfolioBloc = styled(Row)<{ background: string }>`
  padding: 7.5rem 0 4.5rem;
  ${PrimaryTitle} span {
    color: ${({ theme }) => theme.Colors.red};
  }
  ${ButtonBlack} {
    margin-top: 2.5rem;
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    padding: 5rem 0 3rem;
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.sm}px) {
    padding: 12.5rem 0 37px;
    flex-direction: column;
    color: ${({ theme }) => theme.Colors.white};
    background: url('${({ background }) => background}') no-repeat center center;
    background-size: cover;
    ${PrimaryTitle} {
      margin-bottom: 0.5rem;
      span {
        color: ${({ theme }) => theme.Colors.white};
      }
      & + button {
        margin-top: 35px;
      }
    }
  }
`

export const PortfolioBlocButton = styled(ButtonBlack)`
  @media (max-width: ${({ theme }) => theme.breakpoints.sm}px) {
    background: ${({ theme }) => theme.Colors.red};
  }
`

export const PortfolioBlocLogos = styled.div``

export const PortfolioLogosDigiact = styled.div`
  display: flex;
  align-items: center;
  @media (max-width: ${({ theme }) => theme.breakpoints.sm}px) {
    display: none;
  }
`

export const PortfolioBlocLogosImg = styled(Image)`
  max-width: 6rem;
  width: calc(33% - 1rem);
  height: auto;
  margin-top: 5rem;
  margin-right: 2rem;
`

export const PortfolioBlocVideo = styled.video`
  width: 100%;
  height: 100%;
`

export const PortfolioBlocContainer = styled(Container)<{
  isReverse?: boolean;
  isQomben?: boolean
}>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: ${({ isReverse }) => (isReverse ? 'row-reverse' : 'row')};
  text-align: ${({ isReverse }) => (isReverse ? 'right' : 'left')};
  ${Col2} {
    width: ${ ({ isQomben }) => isQomben ? '45%' : 'calc(50% - 3rem)' };
    &:last-child {
      width: ${ ({ isQomben }) => isQomben ? '55%' : '' };
    }
  }
  ${PortfolioBlocLogos} ul {
    justify-content: ${({ isReverse }) =>
      isReverse ? 'flex-end' : 'flex-start'};
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.lg}px) {
    ${Col2} {
      width: ${ ({ isQomben }) => isQomben ? 'calc(50% - 0.5rem)' : '' };
      &:last-child {
        width: ${ ({ isQomben }) => isQomben ? 'calc(50% - 0.5rem)' : '' };
      }
    }
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    ${Col2} {
      width: calc(50% - 1.5rem);
    }
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.sm}px) {
    ${Col2} {
      width: 100%;
      &:last-child {
        display: none;
      }
    }
  }
`

import React, { FunctionComponent } from 'react'
// @ts-ignore
import { LocalizedLink as Link } from 'gatsby-theme-i18n'
import { Trans } from '@lingui/macro'
import { Col2, MainText, PrimaryTitle, TextRed } from 'styles/Global'
import ListTags from 'components/atoms/ListTags/ListTags'
import PortfolioLogos from 'components/atoms/PortfolioLogos/PortfolioLogos'
import Image from 'components/Image'
import {
  PortfolioBloc,
  PortfolioBlocButton,
  PortfolioBlocContainer,
  PortfolioBlocLogos,
  PortfolioBlocLogosImg,
  PortfolioBlocVideo,
  PortfolioLogosDigiact,
} from './PortfolioBlocs.style'

// GIF
import MobilityPoster from 'assets/images/portfolio/poster/mobility.png'
import ShowroomPoster from 'assets/images/portfolio/poster/showroom.png'
import FusionPoster from 'assets/images/portfolio/poster/fusion.png'
import IntentalPoster from 'assets/images/portfolio/poster/intental.png'
import VariabilityPoster from 'assets/images/portfolio/poster/variability.png'

// VIDEO
import MobilityVideo from 'assets/images/portfolio/video/mobility.mp4'
import ShowroomVideo from 'assets/images/portfolio/video/showroom.mp4'
import FusionVideo from 'assets/images/portfolio/video/fusion.mp4'
import IntentalVideo from 'assets/images/portfolio/video/intental.mp4'
import VariabilityVideo from 'assets/images/portfolio/video/variability.mp4'

// Background
import MobilityBg from 'assets/images/portfolio/mobile/mobilityBg.png'
import ShowroomBg from 'assets/images/portfolio/mobile/showroomBg.png'
import FusionBg from 'assets/images/portfolio/mobile/fusionBg.png'
import GeomaskBg from 'assets/images/portfolio/mobile/geomaskBg.png'
import IntentalBg from 'assets/images/portfolio/mobile/intentalBg.png'
import VariabilityBg from 'assets/images/portfolio/mobile/variabilityBg.png'
import QombenBg from 'assets/images/portfolio/mobile/qombenBg.png'
import DigiactBg from 'assets/images/portfolio/mobile/digiactBg.png'

interface Props {
  images: any
}

const PortfolioBlocs: FunctionComponent<Props> = ({ images }) => {
  return (
    <>
      <PortfolioBloc background={MobilityBg}>
        <PortfolioBlocContainer isReverse>
          <Col2>
            <ListTags
              data={[
                <Trans id="portfolio.item1.tag.1" />,
                <Trans id="portfolio.item1.tag.2" />,
              ]}
              alignRight
            />
            <PrimaryTitle as="h2">
              <Trans id="portfolio.item1.title">
                Comment <TextRed>transformer</TextRed> l'industrie de la{' '}
                <TextRed>location</TextRed> de voiture ?
              </Trans>
            </PrimaryTitle>
            <MainText>
              <Trans id="portfolio.item1.text">
                Nous avons digitalisé les opérations des agences de location de
                voiture afin de booster leur productivité et permettre à leurs
                clients d’accèder en libre-service aux véhicules.
              </Trans>
            </MainText>
            <PortfolioBlocButton as={Link} to="/contact">
              <Trans id="portfolio.item1.button">
                Contactez-nous pour une démo
              </Trans>
            </PortfolioBlocButton>
          </Col2>
          <Col2>
            <PortfolioBlocVideo autoPlay loop muted poster={MobilityPoster}>
              <source src={MobilityVideo} type="video/mp4" />
            </PortfolioBlocVideo>
          </Col2>
        </PortfolioBlocContainer>
      </PortfolioBloc>
      <PortfolioBloc background={ShowroomBg}>
        <PortfolioBlocContainer>
          <Col2>
            <ListTags
              data={[
                <Trans id="portfolio.item2.tag.1" />,
                <Trans id="portfolio.item2.tag.2" />,
              ]}
            />
            <PrimaryTitle as="h2">
              <Trans id="portfolio.item2.title">
                Comment <TextRed>transformer</TextRed> l'industrie de la{' '}
                <TextRed>location</TextRed> de voiture ?
              </Trans>
            </PrimaryTitle>
            <MainText>
              <Trans id="portfolio.item2.text">
                Nous avons digitalisé les opérations des agences de location de
                voiture afin de booster leur productivité et permettre à leurs
                clients d’accèder en libre-service aux véhicules.
              </Trans>
            </MainText>
            <PortfolioBlocButton
              as="a"
              href="https://lecatwalk.com"
              target="_blank"
            >
              <Trans id="portfolio.item2.button">En savoir plus</Trans>
            </PortfolioBlocButton>
          </Col2>
          <Col2>
            <PortfolioBlocVideo autoPlay loop muted poster={ShowroomPoster}>
              <source src={ShowroomVideo} type="video/mp4" />
            </PortfolioBlocVideo>
          </Col2>
        </PortfolioBlocContainer>
      </PortfolioBloc>
      <PortfolioBloc background={FusionBg}>
        <PortfolioBlocContainer isReverse>
          <Col2>
            <ListTags
              data={[
                <Trans id="portfolio.item3.tag.1" />,
                <Trans id="portfolio.item3.tag.2" />,
                <Trans id="portfolio.item3.tag.3" />,
                <Trans id="portfolio.item3.tag.4" />,
              ]}
              alignRight
            />
            <PrimaryTitle as="h2">
              <Trans id="portfolio.item3.title">
                Comment <TextRed>révolutionner</TextRed> l’utilisation du{' '}
                <TextRed>no-code</TextRed> dans l’entreprise ?
              </Trans>
            </PrimaryTitle>
            <MainText>
              <Trans id="portfolio.item3.text">
                Notre plateforme alimentée par l’IA permet aux managers de créer
                facilement des applications sans code tout en respectant les
                systèmes informatiques et les sécurités déjà existants de
                l’entreprise.
              </Trans>
            </MainText>
            <PortfolioBlocButton
              as="a"
              href="https://mobioos.ai"
              target="_blank"
            >
              <Trans id="portfolio.item3.button">En savoir plus</Trans>
            </PortfolioBlocButton>
          </Col2>
          <Col2>
            <PortfolioBlocVideo autoPlay loop muted poster={FusionPoster}>
              <source src={FusionVideo} type="video/mp4" />
            </PortfolioBlocVideo>
          </Col2>
        </PortfolioBlocContainer>
      </PortfolioBloc>
      <PortfolioBloc background={VariabilityBg}>
        <PortfolioBlocContainer>
          <Col2>
            <ListTags
              data={[
                <Trans id="portfolio.item4.tag.1" />,
                <Trans id="portfolio.item4.tag.2" />,
              ]}
            />
            <PrimaryTitle as="h2">
              <Trans id="portfolio.item4.title">
                <TextRed>Variabilisez</TextRed> vos applications pour répondre à
                vos attentes.
              </Trans>
            </PrimaryTitle>
            <PortfolioBlocButton as={Link} to="/contact">
              <Trans id="portfolio.item4.button">
                Contactez-nous pour une démo
              </Trans>
            </PortfolioBlocButton>
            <PortfolioBlocLogos>
              <PortfolioLogos />
            </PortfolioBlocLogos>
          </Col2>
          <Col2>
            <PortfolioBlocVideo autoPlay loop muted poster={VariabilityPoster}>
              <source src={VariabilityVideo} type="video/mp4" />
            </PortfolioBlocVideo>
          </Col2>
        </PortfolioBlocContainer>
      </PortfolioBloc>
      <PortfolioBloc background={IntentalBg}>
        <PortfolioBlocContainer isReverse>
          <Col2>
            <ListTags
              data={[
                <Trans id="portfolio.item5.tag.1" />,
                <Trans id="portfolio.item5.tag.2" />,
              ]}
              alignRight
            />
            <PrimaryTitle as="h2">
              <Trans id="portfolio.item5.title">
                Les nouvelles interfaces sont maintenant à l’air de la{' '}
                <TextRed>programmation métier</TextRed>.
              </Trans>
            </PrimaryTitle>
            <PortfolioBlocButton as={Link} to="/contact">
              <Trans id="portfolio.item5.button">
                Contactez-nous pour une démo
              </Trans>
            </PortfolioBlocButton>
            <PortfolioBlocLogos>
              <PortfolioLogos />
            </PortfolioBlocLogos>
          </Col2>
          <Col2>
            <PortfolioBlocVideo autoPlay loop muted poster={IntentalPoster}>
              <source src={IntentalVideo} type="video/mp4" />
            </PortfolioBlocVideo>
          </Col2>
        </PortfolioBlocContainer>
      </PortfolioBloc>
      <PortfolioBloc background={GeomaskBg}>
        <PortfolioBlocContainer>
          <Col2>
            <ListTags
              data={[
                <Trans id="portfolio.item6.tag.1" />,
                <Trans id="portfolio.item6.tag.2" />,
              ]}
            />
            <PrimaryTitle as="h2">
              <Trans id="portfolio.item6.title">
                Comment avons-nous <TextRed>contribué</TextRed> à lutter contre
                la <TextRed>covid-19</TextRed> ?
              </Trans>
            </PrimaryTitle>
            <MainText>
              <Trans id="portfolio.item6.text">
                Redfabriq s’est mobilisée pour réaliser en deux semaines une
                application pour rendre visible les lieux où le port du masque
                est obligatoire ainsi que les points de vente de gels
                hydroalcooliques et de masques.
              </Trans>
            </MainText>
            <PortfolioBlocButton as={Link} to="/contact">
              <Trans id="portfolio.item6.button">En savoir plus</Trans>
            </PortfolioBlocButton>
          </Col2>
          <Col2>
            <Image fluid={images.geomask.fluid} alt={images.geomask.alt} />
          </Col2>
        </PortfolioBlocContainer>
      </PortfolioBloc>
      <PortfolioBloc background={QombenBg}>
        <PortfolioBlocContainer isReverse isQomben>
          <Col2>
            <ListTags
              data={[
                <Trans id="portfolio.item7.tag.1" />,
                <Trans id="portfolio.item7.tag.2" />,
                <Trans id="portfolio.item7.tag.3" />,
              ]}
              alignRight
            />
            <PrimaryTitle as="h2">
              <Trans id="portfolio.item7.title">
                Menez à bien la stratégie de <TextRed>rémunération</TextRed> et{' '}
                <TextRed>avantages</TextRed> sociaux de votre entreprise.
              </Trans>
            </PrimaryTitle>
            <PortfolioBlocButton
              as="a"
              href="https://www.qomben.com"
              target="_blank"
            >
              <Trans id="portfolio.item7.button">En savoir plus</Trans>
            </PortfolioBlocButton>
          </Col2>
          <Col2>
            <Image fluid={images.qomben.fluid} alt={images.qomben.alt} />
          </Col2>
        </PortfolioBlocContainer>
      </PortfolioBloc>
      <PortfolioBloc background={DigiactBg}>
        <PortfolioBlocContainer>
          <Col2>
            <ListTags
              data={[
                <Trans id="portfolio.item8.tag.1" />,
                <Trans id="portfolio.item8.tag.2" />,
                <Trans id="portfolio.item8.tag.3" />,
                <Trans id="portfolio.item8.tag.4" />,
              ]}
            />
            <PrimaryTitle as="h2">
              <Trans id="portfolio.item8.title">
                Une <TextRed>plateforme</TextRed> de formations numériques
                financés à 100% par l'Etat français pour la{' '}
                <TextRed>transformation numérique</TextRed> des{' '}
                <TextRed>TPE</TextRed> et <TextRed>PME</TextRed>.
              </Trans>
            </PrimaryTitle>
            <PortfolioBlocButton as={Link} to="/digiact">
              <Trans id="portfolio.item8.button">En savoir plus</Trans>
            </PortfolioBlocButton>
            <PortfolioLogosDigiact>
              <PortfolioBlocLogosImg
                fluid={images.republique.fluid}
                alt={images.republique.alt}
              />
              <PortfolioBlocLogosImg
                fluid={images.bpi.fluid}
                alt={images.bpi.alt}
              />
              <PortfolioBlocLogosImg
                fluid={images.francenum.fluid}
                alt={images.francenum.alt}
              />
            </PortfolioLogosDigiact>
          </Col2>
          <Col2>
            <Image fluid={images.digiact.fluid} alt={images.digiact.alt} />
          </Col2>
        </PortfolioBlocContainer>
      </PortfolioBloc>
    </>
  )
}

export default PortfolioBlocs

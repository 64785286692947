import styled from 'styled-components'

export const PortfolioLogosItem = styled.li`
  width: auto;
  &:not(:last-child) {
    margin-right: 3.5rem;
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.xs}px) {
    margin: 0 0.5rem;
    width: calc(25% - 1rem);
  }
`

export const PortfolioLogosList = styled.ul<{ isHome?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  //flex-wrap: wrap;
  margin-top: 5rem;
  ${PortfolioLogosItem} {
    width: ${({ isHome }) =>
      isHome ? 'calc(25% - 2rem)' : 'auto'};
  } 
  @media (max-width: ${({ theme }) => theme.breakpoints.lg}px) {
    ${PortfolioLogosItem} {
      width: ${({ isHome }) =>
        isHome ? 'calc(33% - 2rem)' : 'calc(25% - 2rem)'};
      margin: ${({ isHome }) => (isHome ? '0 1rem' : '0 1rem 2rem')};
    }
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    max-width: ${({ isHome }) => (isHome ? '60%' : 'unset')}; 
    margin-left: ${({ isHome }) => (isHome ? 'auto' : 'unset')};
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.sm}px) {
    margin-top: 2.5rem;
    display: ${({ isHome }) => (isHome ? 'flex' : 'none')};
    ${PortfolioLogosItem} {
      width: ${({ isHome }) => (isHome ? 'calc(33% - 1rem)' : 'unset')};
      margin: 0 .5rem;
    }
  @media (max-width: ${({ theme }) => theme.breakpoints.xs}px) {
    max-width: 90%;
  }
`
